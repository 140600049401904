import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import { parseUrl } from "query-string";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import _ from "lodash";

import TextInput from "../../components/TextInput";
import PasswordInput from "../../components/PasswordInput ";
import "./Login.css";
import { authenticate, changeIsAuth, LOGOUT } from "../../store/actions/auth";
import { validateEmail, validatePassword, clearCookie } from "../../methods";
import { switchSDAndLogin } from "../../methods/auth_methods";
import ButtonWithLoader from "../../components/ButtonWithLoader";
import LoginIssuesContact from "./LoginIssuesContact";

const cookies = new Cookies();
function LoginPage(props) {
  const navigate = useNavigate();
  const routerLoc = useLocation();

  // redux state variable

  const isAuth = useSelector((state) => state.auth.isAuth);
  const postLogin = useSelector((state) => state.auth.postLogin);
  const state = useSelector((state) => state);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const errorHTML = useSelector((state) => state.auth.errorHTML);

  const dispatch = useDispatch();

  const login = (email, password, authCode) =>
    dispatch(authenticate(email, password, authCode));
  const logoutAction = () => dispatch({ type: LOGOUT });
  const reloadState = (payload) => dispatch({ type: "RELOAD_STATE", payload });
  const changeIsAuthAction = (data) => dispatch(changeIsAuth(data));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [responseError, setresponseError] = useState("");

  var url_string = window.location.href;
  var url = new URL(url_string);
  var message = url.searchParams.get("show_message");

  useEffect(() => {
    const parsed = parseUrl(window.location.search);
    setAuthCode(parsed.query.auth_code);
    if (message === "confirmation_error") {
      toast.error("Email confirmation error");
    } else if (message === "email_confirmation") {
      toast.success("Email confirmed successfully");
    }
  }, []);

  // useEffect(() => {
  //   changeIsAuthAction({ error: false, errorHTML: "" });
  // }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => changeIsAuthAction({ error: false }), 10000);
    }
  }, [error]);

  let onTextInput = (e) => {
    if (!validateEmail(e.target.value.trim())) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail(e.target.value.toLowerCase());
    }
  };

  let onPasswordInput = (e) => {
    // if (!validatePassword(e.target.value)) {
    //   setPasswordError(true);
    // } else {
    //   setPasswordError(false);
    setPassword(e.target.value);
    // }
  };

  let handleLogin = async (e) => {
    e.preventDefault();
    if (
      emailError ||
      // passwordError ||
      email.length === 0
      // || password.length === 0
    ) {
      setEmailError(true);
      // setPasswordError(true);
    } else if (passwordError || password.length === 0) {
      // setEmailError(true);
      setPasswordError(true);
    } else {
      let response = await login(email, password, authCode);
      console.log("checking rh:", response);
      // handling error
      if (response.error) return setresponseError("show");

      // handling success
      if (response?.payload?.password_redirect_url) {
        navigate(response?.payload?.password_redirect_url);
        return;
      }

      const token = response.payload.token;
      const decodedData = jwt_decode(response.payload.token);
      const practices = decodedData.practices;
      // checking this page redirected from report page
      const to = parseUrl(props.location?.state?.from?.search || "").query;
      const subdomain = window.location.host.split(".")[0];
      const currentPractice = _.find(practices, { subdomain });
      const postLogin = response.payload.post_login;
      if (
        response?.payload?.mfa_enabled &&
        (practices?.length === 1 || decodedData.user_type !== "User")
      ) {
        navigate("/users/verify_otp", {
          state: {
            toReport: to.patient_id && currentPractice ? true : false,
            report: props.location?.state,
          },
        });
      } else {
        if (decodedData.user_type === "AdminUser") {
          reloadState({
            token,
            ...decodedData,
            practiceID: cookies.get("p_id"),
            practice: cookies.get("practice"),
          });
          clearCookie();
        } else if (decodedData.user_type === "Patient") {
          const domain = practices.subdomain;
          const practiceId = practices.id;
          const search = routerLoc.search;
          let path = `/patient/dashboard?${search}`;
          let language = decodedData.user.language;
          let termsAgreed = decodedData.user.terms_agreed;
          let showDOBBanner =
            _.isEmpty(decodedData.user.dob) ||
            _.isEmpty(decodedData.user.gender) ||
            _.isEmpty(decodedData.user.skin_color);
          if (decodedData.user.welcome_shown) {
            path = `/patient/dashboard${search}`;
          }
          switchSDAndLogin(
            token,
            domain,
            practiceId,
            path,
            termsAgreed,
            language,
            showDOBBanner
          );
        } else if (decodedData.user_type === "User") {
          if (practices.length === 0) {
            toast.error("Invalid User");
          } else if (practices.length === 1) {
            const search = routerLoc.search;
            let domain = practices[0].subdomain,
              practiceId = practices[0].id,
              path = `/practice/dashboard${search}`;
            let termsAgreed = practices[0]?.terms_agreed;
            let language = decodedData.user.language;
            let country = decodedData.user?.country;
            if (postLogin) path = `/hcp/educational_1`;
            switchSDAndLogin(
              token,
              domain,
              practiceId,
              path,
              termsAgreed,
              language,
              false
            );
          } else
            navigate("/users/domain", {
              state: {
                toReport: to.patient_id && currentPractice ? true : false,
                report: props.location?.state,
              },
            });
        }
      }
    }
  };
  const { t, i18n } = useTranslation();
  return (
    <div className="container sign-in__container pt-md-5">
      <div className="sign-in__title text-center my-4 mt-md-3">
        {t("auth_page_line_1")}
      </div>
      {i18n.language === "en" && (
        <div className="row justify-content-center">
          <div className="sign-in__title-2 col-sm-12 col-md-10 col-lg-6 mb-3">
            {t("auth_page_line_2")}{" "}
            <a
              className="my-3 cursor-pointer"
              style={{ fontSize: 22, fontWeight: 700 }}
              target="_blank"
              href={process.env.REACT_APP_CANADIAN_LOGIN_URL}
              rel="noreferrer"
            >
              {t("auth_page_line_2_1")}
            </a>
          </div>
        </div>
      )}
      {error && (
        <div
          className="text-danger text-center"
          dangerouslySetInnerHTML={{ __html: errorHTML }}
        />
      )}
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-10 col-lg-6 mb-4 mb-md-5">
          <form onSubmit={handleLogin}>
            <TextInput
              // label="Email Address"
              type="text"
              showLabel={false}
              inputHandler={onTextInput}
              placeholder={t("auth_page_email_ph")}
              error={emailError}
              errorMessage={t("auth_page_email_error")}
              id="email-input__loginpage"
              emailField={true}
            />
            <PasswordInput
              // label="Password"
              inputHandler={onPasswordInput}
              placeholder={t("auth_page_pwd_ph")}
              error={passwordError}
              errorMessage={t("auth_page_pwd_error")}
              id="password-input__loginpage"
            />
            <button
              type="submit"
              className="btn w-100 btn-signin"
              style={{ background: "var(--pg_primary) !important" }}
              id="sign-in-btn__loginpage"
            >
              {t("prac_register_sign_in_text")}
            </button>
          </form>
          <div style={{ letterSpacing: 1 }}>
            {t("auth_page_line_3")}{" "}
            <Link
              className="my-3 cursor-pointer btn-forgot-pwd d-inline-block"
              to="/users/fp"
              id="fp-btn__loginpage"
            >
              {t("auth_page_btn_1")}
            </Link>
          </div>
        </div>
      </div>
      {i18n.language === "en" && (
        <div className="text-center fw-bolder my-3 d-flex flex-wrap justify-content-center">
          <Link
            to="/hcp/registration"
            className="my-3 cursor-pointer btn-forgot-pwd d-inline-block mx-2"
          >
            Practitioner Registration
          </Link>
          <Link
            to="/patient/self-register"
            className="my-3 cursor-pointer btn-forgot-pwd d-inline-block mx-2"
          >
            Patient Registration
          </Link>
        </div>
      )}
      <div className="mb-2 text-center">{t("auth_page_line_4")}</div>
    </div>
  );
}

export default LoginPage;
