import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import TextInput from "../../components/TextInput";
import ActionButton from "../../components/Practice/AddPatientPage/ActionButton";
import { validateEmail } from "../../methods";

import {
  addPatient,
  getPracticeSetting,
  getCountries,
} from "../../store/actions/addPatient";
import * as UI from "../../store/actions/ui";
import { PgRadioButton } from "../../components/PgRadioButton";
import PgCheckbox from "../../components/PgCheckbox";
import PgSelect from "../../components/PgSelect";
import PgSelectTooltip from "../../components/PgSelectTooltip";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { months, years } from "../../methods/constant";
import moment from "moment";
import QRCodeGenerator from "../../components/QRCodeGenerator";
import PgInfoTooltip from "../../components/PgInfoTooltip";
import PILogo from "../../assets/PureInsight_Logo_RGB_u1.svg";

function AddPatientPage() {
  const location = useLocation();
  // redux state variable

  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const practiceFirstName = useSelector((state) => state.auth.firstName);
  const practiceLastName = useSelector((state) => state.auth.lastName);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  // redux actions

  const dispatch = useDispatch();

  const addPatientAction = (token, currentPracticeID, currentPractice, data) =>
    dispatch(addPatient(token, currentPracticeID, currentPractice, data));
  const showAPDialog = () => dispatch({ type: UI.SHOW_ADD_PATIENT_DIALOG });
  const getPracticeSettingAction = (
    token,
    currentPracticeID,
    currentPractice
  ) => dispatch(getPracticeSetting(token, currentPracticeID, currentPractice));

  const { t, i18n } = useTranslation();
  const practitionerCountry = useSelector((state) => state.auth.country);
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();

  // for radio button
  const [patientType, setpatientType] = useState("0");
  const [myself_patient, setMyselfPatient] = useState(false);

  // for input fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [skincolor, setSkinColor] = useState("");
  const [country, setCountry] = useState("");
  const [pCode, setpCode] = useState();

  // for checkbox
  const [isPatientActive, setisPatientActive] = useState(false);
  const [isDone, setIsDone] = useState(true);
  const [showFields, setShowFields] = useState(true);

  // for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailAvailabilityError, setEmailAvailabilityError] = useState(false);

  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColorError, setskinColorError] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [patientTypeError, setPatientTypeError] = useState(false);

  useEffect(() => {
    getPracticeSettingAction(token, currentPracticeID, currentPractice).then(
      (practiceSetting) => {
        console.log("patient setting:", practiceSetting.payload);
        setpCode(practiceSetting.payload?.practice_code);
        setMyselfPatient(practiceSetting.payload.myself_patient);
      }
    );
    dispatch(getCountries(token, currentPracticeID, currentPractice)).then(
      (response) => {
        if (!response.error) {
          var availableCountries = response.payload?.countries;
          if (practitionerCountry !== "US" && practitionerCountry !== "CA") {
            setCountry("");
            _.remove(availableCountries, function (n) {
              return n === "United States";
            });
            _.remove(availableCountries, function (n) {
              return n === "Canada";
            });
          } else if (practitionerCountry === "CA") {
            setCountry("Canada");
          } else {
            setCountry("United States");
          }
          setCountryList(availableCountries);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const setPatientTypeFunc = (val) => {
    setShowFields(false);
    setIsDone(true);
    setfName("");
    setlName("");
    setEmail("");
    setAge("");
    setGender("");
    setSkinColor("");

    if (val === "1") {
      setShowFields(false);
      setisPatientActive(false);
      setfName(practiceFirstName);
      const randomNO = new Date().getTime();
      setlName(practiceLastName);
      setEmail("test" + randomNO + "@puregenomics.com");
      setIsDone(false);
      setPatientTypeError(false);
      setEmailError(false);
      setfNameError(false);
      setlNameError(false);
      setGenderError("");
      setskinColorError("");
      setAgeError("");
      setCountryError(false);
    }
    if (val === "2") {
      setShowFields(false);
      setisPatientActive(false);
      setfName("Test");
      const randomNO = new Date().getTime();
      setlName(randomNO);
      setEmail("test" + randomNO + "@puregenomics.com");
      setAge(new Date(new Date().setFullYear(new Date().getFullYear() - 20)));
      setGender("Male");
      setIsDone(false);
      setPatientTypeError(false);
      setEmailError(false);
      setEmailAvailabilityError(false);
      setEmailErrorMessage("");
      setfNameError(false);
      setlNameError(false);
      setGenderError("");
      setskinColorError("");
      setAgeError("");
      setCountryError(false);
    } else if (val === "0") {
      setShowFields(true);
      setIsDone(false);
      getPracticeSettingAction(token, currentPracticeID, currentPractice).then(
        (practiceSetting) => {}
      );
    }
    setpatientType(val);
  };

  const onDone = () => {
    setAgeError("");
    setEmailAvailabilityError(false);
    if (email.length === 0 || !validateEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage("Enter valid Email");
    }
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (!_.isDate(age)) setAgeError(t("pracand_age_error"));
    if (gender.length === 0) setGenderError(t("pracand_gender_error"));
    if (skincolor.length === 0) setskinColorError(t("Skin color is required"));
    if (country.length === 0) setCountryError("Country is required");

    if (patientType === "") setPatientTypeError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      // genetics.length !== 0 &&
      _.isDate(age) &&
      ageError.length <= 0 &&
      gender.length !== 0 &&
      skincolor.length !== 0 &&
      country.length !== 0 &&
      patientType !== ""
    ) {
      setIsValidForm(true);
    } else {
      window.scrollTo(0, 500);
    }
  };

  const handleDone = async () => {
    let data = {
      patient: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        patient_type:
          patientType === "0"
            ? "real"
            : patientType === "1"
            ? "myself"
            : "test",
        status: isPatientActive,
        dob: moment(age).format(),

        gender: gender,
        skin_color: skincolor,
        country: country,
      },
    };
    console.log("data ---------->", data);
    const response = await addPatientAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    );
    if (response.error) {
      setIsValidForm(false);
    } else {
      if (response.payload.email_error) {
        setEmailErrorMessage("");
        setEmailError(true);
        setEmailAvailabilityError(true);
        setIsValidForm(false);
        document.getElementById("f-name__addpatient").scrollIntoView();
      } else {
        let successMessage =
          patientType === "0" ? t("pracand_success_1") : t("pracand_success_2");
        showAPDialog();
        navigate("/practice/patients", {
          state: {
            showSuccessAlert: true,
            successMessage,
          },
        });
      }
    }
  };

  const onCancel = () => {
    if (location.state?.from === "dashboard")
      return navigate("/practice/dashboard");
    navigate(-1);
  };

  const handleInput = (value, errorState, updateValueState) => {
    if (value.length === 0) {
      errorState(true);
    } else {
      errorState(false);
    }
    updateValueState(value);
  };

  return (
    <div>
      <Helmet>
        <title>{t("puregenomics_1")} - Add New Patient</title>
      </Helmet>
      {/* Head section */}
      <div className="container-xl pt-5 px-lg-0 px-4">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/practice/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
        </div>
        {i18n.language === "en" && (
          <QRBanner
            firstName={practiceFirstName}
            lastName={practiceLastName}
            code={pCode}
          />
        )}
        <div className="mt-2 pg-title text-center">{t("pracanp_line_1")}</div>
        {/* <div className="pg-text">
					Complete this simple form to add a new patient to your practice.
				</div> */}
      </div>

      {/* <ActionButton isDone={false} onCancel={onCancel} onDone={onDone} /> */}

      {/* Patient type section */}
      <form className="container-xl py-1 pt-4 px-lg-2 px-4">
        <div className="pg-heading ">{t("pracanp_line_2")}</div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="textc-primary pg-link">{t("required")}</div>
          </div>
          <div className="col-12 col-md-6 pg-text">
            <div>{t("pracanp_line_3")}</div>
            <div className="px-4">
              <PgRadioButton
                name="p_type"
                checked={patientType === "0"}
                value="0"
                onCheck={() => setPatientTypeFunc("0")}
                title={t("pracanp_line_4")}
                id="radio__newpatient"
              />
              {!myself_patient && (
                <PgRadioButton
                  name="p_type"
                  checked={patientType === "1"}
                  value="1"
                  onCheck={() => setPatientTypeFunc("1")}
                  title={t("pracanp_line_5")}
                  id="radio__myself"
                />
              )}
              <PgRadioButton
                name="p_type"
                checked={patientType === "2"}
                value="2"
                onCheck={() => setPatientTypeFunc("2")}
                title={t("pracanp_line_6")}
                id="radio__testpatient"
              />
            </div>
            <div
              className="text-danger my-1 transition-all mx-1"
              style={patientTypeError ? { opacity: 1 } : { opacity: 0 }}
            >
              <i className="fas fa-info-circle pr-2"></i>{" "}
              {t("pracand_pt_error")}
            </div>
            <TextInput
              label={t("pracand_fname")}
              showLabel={false}
              required={true}
              optional={false}
              id="f-name__addpatient"
              placeholder={t("pracand_fname_ph")}
              value={fName}
              error={fNameError}
              type="text"
              errorMessage={t("pracand_fname_error")}
              inputHandler={(e) => {
                let reg = /^[0-9a-zA-Z\_]+$/;
                if (reg.test(e.target.value) || e.target.value === "") {
                  handleInput(e.target.value, setfNameError, setfName);
                }
              }}
            />
            <TextInput
              label={t("pracand_lname")}
              showLabel={false}
              required={true}
              optional={false}
              id="l-name__addpatient"
              placeholder={t("pracand_lname_ph")}
              value={lName}
              error={lNameError}
              errorMessage={t("pracand_lname_error")}
              inputHandler={(e) => {
                let reg = /^[0-9a-zA-Z\_]+$/;
                if (reg.test(e.target.value) || e.target.value === "") {
                  handleInput(e.target.value, setlNameError, setlName);
                }
              }}
            />
            <div className="pb-2">
              <TextInput
                label={t("pracand_email")}
                showLabel={false}
                emailField={true}
                isDisable={patientType === "1" || patientType === "2"}
                required={true}
                optional={false}
                id="email-input__addpatient"
                placeholder={t("pracand_email_ph")}
                value={email}
                error={emailError}
                errorMessage={emailErrorMessage}
                inputHandler={(e) => {
                  if (
                    e.target.value.length < 0 &&
                    !validateEmail(e.target.value)
                  ) {
                    setEmailError(true);
                  } else {
                    setEmailAvailabilityError(false);
                    setEmailError(false);
                    setEmail(e.target.value);
                  }
                }}
                style={{ position: "relative" }}
              />
              {emailAvailabilityError && (
                <div
                  className="email-error-wrapper text-danger"
                  dangerouslySetInnerHTML={{ __html: t("pracand_email_error") }}
                />
              )}
            </div>
            <div className="mt-4 my-2 dateVal">
              <ReactDatePicker
                renderCustomHeader={({ date, changeYear, changeMonth }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <select
                      value={date?.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                onChange={(date) => {
                  setAge(date);
                }}
                style={{ width: "100%" }}
                value={age}
                dateFormat="MM/dd/yyyy"
                onChangeRaw={() => {
                  setAge("");
                }}
                selected={age}
                className={_.isEmpty(ageError) ? "dateFld" : "dateFld error"}
                placeholderText="Enter DOB MM/DD/YYYY"
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
                popperPlacement="botom"
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
              <div
                className="text-danger mx-2 my-1 transition-all"
                style={
                  !_.isEmpty(ageError)
                    ? { opacity: 1 }
                    : { opacity: 0, minHeight: "16px" }
                }
              >
                {!_.isEmpty(ageError) && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<i class="fas fa-info-circle"></i>` + ageError,
                    }}
                  ></div>
                )}
              </div>
            </div>
            <PgSelect
              label={t("pracand_gender")}
              style={{
                marginTop: "20% !important",
                marginBottom: "20% !important",
              }}
              showLabel={false}
              required={true}
              optional={false}
              id="gender__addpatient"
              setSelectedItem={setGender}
              selectedItem={gender}
              placeholder={t("pracand_gender_ph")}
              practiceAddPatient={true}
              options={["Male", "Female", "Intersex"]}
              error={gender !== "" ? "" : genderError}
            />
            <PgSelect
              label={t("Patient Skin Color")}
              showLabel={false}
              required={true}
              optional={false}
              id="skincolor__addpatient"
              setSelectedItem={setSkinColor}
              selectedItem={skincolor}
              placeholder={t(
                "Patient Skin Color(Required for Vitamin D Polygenic Scoring)"
              )}
              options={["Dark", "Medium", "Light"]}
              practiceAddPatient={true}
              error={skincolor !== "" ? "" : skinColorError}
            />
            {practitionerCountry !== "CA" && practitionerCountry !== "US" && (
              <PgSelectTooltip
                required={true}
                optional={false}
                id="country__addpatient"
                setSelectedItem={setCountry}
                selectedItem={country}
                placeholder="Enter Patient Country"
                options={countryList}
                practiceAddPatient={true}
                error={countryError}
                tooltipText="Patient location is required for implementation of GDPR"
              />
            )}
          </div>
        </div>
      </form>

      <ActionButton isDone={false} onCancel={onCancel} onDone={onDone} t={t} />
    </div>
  );
}

export default AddPatientPage;

const QRBanner = ({ firstName, lastName, code }) => {
  return (
    <div
      className="my-4 px-3 py-3 d-flex justify-content-between rounded align-items-center flex-wrap"
      style={{ background: "#f7f8fa" }}
    >
      <div className="d-flex  align-items-center">
        <h5 className="fw-bold my-0">{firstName + " " + lastName} </h5>
        <p className="mx-2 my-auto">#{code}</p>
        <PgInfoTooltip position="right center">
          Please share your unique practitioner code or QR code with your <br />
          patients so they can register for PureInsight. Or you can add <br />
          patients directly to your account and they will receive an email{" "}
          <br />
          inviting them to join.
        </PgInfoTooltip>
      </div>
      <QRCodeGenerator
        qrColor="#14328c" // Custom QR color
        paragraph={`Scan this unique QR code from your
                  healthcare professional to easily register for
                  your Pure Patient Direct account.`}
        headerText="PureInsight"
        logoUrl={PILogo}
        qrText={`${process.env.REACT_APP_PG3_WEB_APP_DOMAIN}/patient/self-register?practice_code=${code}`}
        btnText="Download Practitioner QR Code"
      />
    </div>
  );
};
